@import "../node_modules/primeicons/primeicons.css";

@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";

@import "../node_modules/primeng/resources/primeng.min.css";

.p-datatable tr:nth-child(even) {
    background-color: #f5f5f5;
}

.p-datatable tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.p-calendar .p-inputtext {
    line-height: 1.5rem !important;
    border-radius: 5px !important;
    border: 2px solid #dadfe6;
    height: 2.5rem;
    width: 17rem !important;
}
