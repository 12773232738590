@media screen and (max-width: 992px) {
    .push-right {
        .sidebar {
            left: 235px !important;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .dash-card {
        .card-body {}
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .dash-card {
        .card-body {}
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .dash-card {
        .card-body {
            min-height: 349px !important;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .dash-card {
        .card-body {
            max-height: 381px !important;
            min-height: 381px !important;
        }
    }
}


$enable-negative-margins: true;